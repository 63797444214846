import React, { useState, useRef } from 'react';
import styles from './compactor.module.scss';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Compactor = () => {
  //test
  const previewUploaderRef = useRef(null);
  const layoutUploaderRef = useRef(null);
  const [upload, setUpload] = useState({
    preview: null,
    layout: null,
  });

  const [image, setImage] = useState({ preview: '', raw: '' });
  const [state, setState] = useState({
    mouth: null,
    horn: null,
    eyes: null,
    outfit: null,
    fur: null,
    earring: null,
    background: null,
  });
  const { mouth, horn, eyes, outfit, fur, background, earring } = state;
  const urls = {
    fur: 'https://goatz.mypinata.cloud/ipfs/QmedzRdygyqQhPD3E9Nt66Rx3bVQ3vkpUwrBWGHwVYNQfE/',
    mouth:
      'https://goatz.mypinata.cloud/ipfs/Qme7PSPz9TFefp1Z3j8XX6itE5AYyywM6Nx9DR7ftkBHkA/',
    outfit:
      'https://goatz.mypinata.cloud/ipfs/QmdPQwHVrSFgLVEWUJ656aYLTeUbZQuCTTu6MB74fwjXTi/',
    eyes: 'https://goatz.mypinata.cloud/ipfs/QmXxh5mifWBhnAW6zPaYp8QbnEipMiP47NX92AfqZ2NwME/',
    horn: 'https://goatz.mypinata.cloud/ipfs/QmfB4oTStFbUohMeSiRTWLmkAhUjLpnUd6rrSCfmhTceuB/',
    earring:
      'https://goatz.mypinata.cloud/ipfs/QmdYf4coDTddq5z1rVL9HKBbNdArace8fm48hMTqAcHdLJ/',
    background:
      'https://goatz.mypinata.cloud/ipfs/QmUQ7zk8hguK2U4Bt874oeqx1wFKAB3CKie1rnZYLKgPvU/',
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: `${urls[name]}${value}` });
  };

  const { preview, layout } = state;
  const handleFileChange = (event) => {
    alert(event.target.name);
    const reader = new FileReader();
    reader.onload = function () {
      console.log('inside onload');
      setState({ ...state, [event.target.name]: reader.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    console.log('event info ', event.target.name);
    console.log(
      'byclassname',
      (document.getElementById('button1').style = 'display: none')
    );

    if (event.target.name === 'preview') {
      document.getElementById('button1').style = 'display: none';
    }
    if (event.target.name === 'layout') {
      document.getElementById('button2').style = 'display: none';
    }
  };

  const handleSave = async () => {
    let element = document.getElementById('capture');
    html2canvas(element, {
      useCORS: true,
      onRendered: function (canvas) {
        console.log(canvas);
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const screenCaptureSource = imgData;
      const downloadLink = document.createElement('a');
      const fileName = 'react-screen-capture.png';
      downloadLink.href = screenCaptureSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };
  const stateReset = () => {
    setState({
      ...state,
      mouth: null,
      horn: null,
      eyes: null,
      outfit: null,
      fur: null,
      earring: null,
      background: null,
    });
  };
  return (
    <div className={styles.Main} id="capture">
      <link rel="stylesheet" href="https://use.typekit.net/wlo0nco.css"></link>
      <div className={styles.Forge}>
        <div className={styles.middiv}>
          <h2>The Forge</h2>
          <span>
            Upload Two of your GOATz <br /> and create a preview of your Forged
            GOAT
          </span>
          <div className={styles.second}>
            <div className={styles.last}>
              <div className={styles.uploadbtn}>
                <input
                  ref={previewUploaderRef}
                  type="file"
                  style={{ display: 'none' }}
                  name="preview"
                  onChange={handleFileChange}
                />
                <input
                  ref={layoutUploaderRef}
                  type="file"
                  style={{ display: 'none' }}
                  name="layout"
                  onChange={handleFileChange}
                />
                <button
                  id="button1"
                  className={styles.button1}
                  type="button"
                  onClick={() => previewUploaderRef.current.click()}
                >
                  <p>Upload Image 1</p>
                </button>
                <button
                  id="button2"
                  className={styles.button2}
                  type="button"
                  onClick={() => layoutUploaderRef.current.click()}
                >
                  <p>Upload Image 2</p>
                </button>
              </div>
              <div className={styles.image}>
                {preview ? (
                  <img src={preview} className={styles.upload} />
                ) : null}
              </div>
              <div className={styles.image}>
                {layout ? <img src={layout} className={styles.upload} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <h2>Create a GOATz Preview!</h2>
        {/* </ br> */}
        <div className={styles.center}>
          {background ? <img src={background} /> : null}
          {fur ? <img src={fur} /> : null}
          {outfit ? <img src={outfit} /> : null}
          {earring ? <img src={earring} /> : null}
          {eyes ? <img src={eyes} /> : null}
          {mouth ? <img src={mouth} /> : null}
          {horn ? <img src={horn} /> : null}
        </div>
      </div>
      <div className={styles.selector}>
        <h2>Trait Selector</h2>
        <div className={styles.options}>
          <div>
            <label>Horn</label>
            {/* <br /> */}
            <select name="horn" onChange={handleChange}>
              <option value={null} disabled selected>
                Horn
              </option>
              <option value="Alt Jockey.png"> Alt Jockey </option>
              <option value="Alt JockeyV2.png"> Alt JockeyV2 </option>
              <option value="Angel.png"> Angel </option>
              <option value="AngelV2.png"> AngelV2 </option>
              <option value="Bald.png"> Bald </option>
              <option value="BaldV2.png"> BaldV2 </option>
              <option value="Beanie.png"> Beanie </option>
              <option value="BeanieV2.png"> BeanieV2 </option>
              <option value="Black.png"> Black </option>
              <option value="BlackV2.png"> BlackV2 </option>
              <option value="Blue Bows.png"> Blue Bows </option>
              <option value="Blue BowsV2.png"> Blue BowsV2 </option>
              <option value="Blue.png"> Blue </option>
              <option value="BlueV2.png"> BlueV2 </option>
              <option value="Captain.png"> Captain </option>
              <option value="CaptainV2.png"> CaptainV2 </option>
              <option value="Cowboy.png"> Cowboy </option>
              <option value="CowboyV2.png"> CowboyV2 </option>
              <option value="Crown.png"> Crown </option>
              <option value="CrownV2.png"> CrownV2 </option>
              <option value="Devil.png"> Devil </option>
              <option value="DevilV2.png"> DevilV2 </option>
              <option value="Ethereal.png"> Ethereal </option>
              <option value="EtherealV2.png"> EtherealV2 </option>
              <option value="Fedora.png"> Fedora </option>
              <option value="FedoraV2.png"> FedoraV2 </option>
              <option value="Firefighter.png"> Firefighter </option>
              <option value="FirefighterV2.png"> FirefighterV2 </option>
              <option value="Flat Bill.png"> Flat Bill </option>
              <option value="Flat BillV2.png"> Flat BillV2 </option>
              <option value="Ghost.png"> Ghost </option>
              <option value="GhostV2.png"> GhostV2 </option>
              <option value="Gold.png"> Gold </option>
              <option value="Golden Headband.png"> Golden Headband </option>
              <option value="Golden HeadbandV2.png"> Golden HeadbandV2 </option>
              <option value="GoldV2.png"> GoldV2 </option>
              <option value="Golf Hat.png"> Golf Hat </option>
              <option value="Golf HatV2.png"> Golf HatV2 </option>
              <option value="Grey.png"> Grey </option>
              <option value="GreyV2.png"> GreyV2 </option>
              <option value="Headband.png"> Headband </option>
              <option value="HeadbandV2.png"> HeadbandV2 </option>
              <option value="Jockey.png"> Jockey </option>
              <option value="JockeyV2.png"> JockeyV2 </option>
              <option value="Mystical.png"> Mystical </option>
              <option value="MysticalV2.png"> MysticalV2 </option>
              <option value="On Fire.png"> On Fire </option>
              <option value="On FireV2.png"> On FireV2 </option>
              <option value="Pilot.png"> Pilot </option>
              <option value="PilotV2.png"> PilotV2 </option>
              <option value="Pink Bows.png"> Pink Bows </option>
              <option value="Pink BowsV2.png"> Pink BowsV2 </option>
              <option value="Pink.png"> Pink </option>
              <option value="PinkV2.png"> PinkV2 </option>
              <option value="Pool Noodles.png"> Pool Noodles </option>
              <option value="Pool NoodlesV2.png"> Pool NoodlesV2 </option>
              <option value="Purple.png"> Purple </option>
              <option value="PurpleV2.png"> PurpleV2 </option>
              <option value="Red Cap.png"> Red Cap </option>
              <option value="Red CapV2.png"> Red CapV2 </option>
              <option value="Red.png"> Red </option>
              <option value="RedV2.png"> RedV2 </option>
              <option value="Regular.png"> Regular </option>
              <option value="RegularV2.png"> RegularV2 </option>
              <option value="Scrunchy.png"> Scrunchy </option>
              <option value="ScrunchyV2.png"> ScrunchyV2 </option>
              <option value="Silver.png"> Silver </option>
              <option value="SilverV2.png"> SilverV2 </option>
              <option value="Soldier.png"> Soldier </option>
              <option value="SoldierV2.png"> SoldierV2 </option>
              <option value="Tan.png"> Tan </option>
              <option value="TanV2.png"> TanV2 </option>
              <option value="Tiger.png"> Tiger </option>
              <option value="TigerV2.png"> TigerV2 </option>
              <option value="Top Hat.png"> Top Hat </option>
              <option value="Top HatV2.png"> Top HatV2 </option>
              <option value="White.png"> White </option>
              <option value="WhiteV2.png"> WhiteV2 </option>
              <option value="Zebra.png"> Zebra </option>
              <option value="ZebraV2.png"> ZebraV2 </option>
              <option value="Zombie.png"> Zombie </option>
              <option value="ZombieV2.png"> ZombieV2 </option>
            </select>
          </div>
          <div>
            <label>Mouth</label>
            {/* <br /> */}
            <select name="mouth" onChange={handleChange}>
              <option value={null} disabled selected>
                Mouth
              </option>
              <option value="Cig.png"> Cig </option>
              <option value="CigV2.png"> CigV2 </option>
              <option value="Cigar.png"> Cigar </option>
              <option value="CigarV2.png"> CigarV2 </option>
              <option value="Determined.png"> Determined </option>
              <option value="DeterminedV2.png"> DeterminedV2 </option>
              <option value="Diamond Grill.png"> Diamond Grill </option>
              <option value="Diamond GrillV2.png"> Diamond GrillV2 </option>
              <option value="Grin.png"> Grin </option>
              <option value="GrinV2.png"> GrinV2 </option>
              <option value="Martini.png"> Martini </option>
              <option value="MartiniV2.png"> MartiniV2 </option>
              <option value="Mic.png"> Mic </option>
              <option value="MicV2.png"> MicV2 </option>
              <option value="Money.png"> Money </option>
              <option value="MoneyV2.png"> MoneyV2 </option>
              <option value="Paintbrush.png"> Paintbrush </option>
              <option value="PaintbrushV2.png"> PaintbrushV2 </option>
              <option value="Pipe.png"> Pipe </option>
              <option value="PipeV2.png"> PipeV2 </option>
              <option value="Radiant.png"> Radiant </option>
              <option value="RadiantV2.png"> RadiantV2 </option>
              <option value="Rainbow.png"> Rainbow </option>
              <option value="RainbowV2.png"> RainbowV2 </option>
              <option value="Toothpick.png"> Toothpick </option>
              <option value="ToothpickV2.png"> ToothpickV2 </option>
              <option value="Whistle.png"> Whistle </option>
              <option value="WhistleV2.png"> WhistleV2 </option>
              
            </select>
          </div>
          <div>
            <label>Eyes</label>
            {/* <br /> */}
            <select name="eyes" onChange={handleChange}>
              <option value={null} disabled selected>
                Eyes
              </option>
              <option value="3D.png"> 3D </option>
              <option value="3DV2.png"> 3D V2 </option>
              <option value="Aviator.png"> Aviator </option>
              <option value="AviatorV2.png"> Aviator V2 </option>
              <option value="BTC.png"> BTC </option>
              <option value="BTCV2.png"> BTC V2 </option>
              <option value="Black Cat.png"> Black Cat </option>
              <option value="Black CatV2.png"> Black Cat V2 </option>
              <option value="Blue.png"> Blue </option>
              <option value="BlueV2.png"> Blue V2 </option>
              <option value="Brown.png"> Brown </option>
              <option value="BrownV2.png"> Brown V2 </option>
              <option value="ETH.png"> ETH </option>
              <option value="ETHV2.png"> ETH V2 </option>
              <option value="FIAT.png"> FIAT </option>
              <option value="FIATV2.png"> FIAT V2 </option>
              <option value="Goggles.png"> Goggles </option>
              <option value="GogglesV2.png"> Goggles V2 </option>
              <option value="Green.png"> Green </option>
              <option value="GreenV2.png"> Green V2 </option>
              <option value="Heterochromia.png"> Heterochromia </option>
              <option value="HeterochromiaV2.png"> Heterochromia V2 </option>
              <option value="Jockey Goggles.png"> Jockey Goggles </option>
              <option value="Jockey GogglesV2.png"> Jockey Goggles V2 </option>
              <option value="Love.png"> Love </option>
              <option value="LoveV2.png"> Love V2 </option>
              <option value="Monocle.png"> Monocle </option>
              <option value="MonocleV2.png"> Monocle V2 </option>
              <option value="On Fire.png"> On Fire </option>
              <option value="On FireV2.png"> On Fire V2 </option>
              <option value="Red Cat.png"> Red Cat </option>
              <option value="Red CatV2.png"> Red Cat V2</option>
              <option value="Round Frames.png"> Round Frames </option>
              <option value="Round FramesV2.png"> Round Frames V2 </option>
              <option value="Shades.png"> Shades </option>
              <option value="ShadesV2.png"> Shades V2 </option>
              <option value="Stoned.png"> Stoned </option>
              <option value="StonedV2.png"> Stoned V2 </option>
              <option value="Trophy.png"> Trophy </option>
              <option value="TrophyV2.png"> Trophy V2 </option>
              <option value="VR.png"> VR </option>
              <option value="VRV2.png"> VR V2 </option>
              <option value="Vibes.png"> Vibes </option>
              <option value="VibesV2.png"> Vibes V2 </option>
            </select>
          </div>
          <div>{/* <br /> */}</div>
          <div>
            <label>Earring</label>
            {/* <br /> */}
            <select name="earring" onChange={handleChange}>
              <option value={null} disabled selected>
                Earring
              </option>
              <option value="Black Pearl.png"> Black Pearl </option>
              <option value="Black PearlV2.png"> Black Pearl V2 </option>
              <option value="Diamond Cross.png"> Diamond Cross </option>
              <option value="Diamond CrossV2.png"> Diamond Cross V2 </option>
              <option value="Double.png"> Double </option>
              <option value="DoubleV2.png"> Double V2</option>
              <option value="Gold Dice.png"> Gold Dice </option>
              <option value="Gold DiceV2.png"> Gold Dice V2 </option>
              <option value="Gold Cross.png"> Gold Cross </option>
              <option value="Gold CrossV2.png"> Gold Cross V2</option>
              <option value="Gold Hoop.png"> Gold Hoop </option>
              <option value="Gold HoopV2.png"> Gold Hoop V2</option>
              <option value="Gold Stud.png"> Gold Stud </option>
              <option value="Gold StudV2.png"> Gold Stud V2</option>
              <option value="None.png"> None </option>
              <option value="Pearl.png"> Pearl </option>
              <option value="PearlV2.png"> Pearl V2</option>
              <option value="Silver Cross.png"> Silver Cross </option>
              <option value="Silver CrossV2.png"> Silver Cross V2</option>
              <option value="Silver Dice.png"> Silver Dice </option>
              <option value="Silver DiceV2.png"> Silver Dice V2</option>
              <option value="Silver Stud.png"> Silver Stud </option>
              <option value="Silver StudV2.png"> Silver Stud V2</option>
                      
            </select>
            {/* deleted div below here  */}

            <div>
              <label>Outfit</label>
              {/* <br /> */}
              <select name="outfit" onChange={handleChange}>
                <option value={null} disabled selected>
                  Outfit
                </option>
                <option value="Alt Basketball.png"> Alt Basketball </option>
                <option value="Alt BasketballV2.png"> Alt BasketballV2 </option>
                <option value="Alt Captain.png"> Alt Captain </option>
                <option value="Alt CaptainV2.png"> Alt CaptainV2 </option>
                <option value="Alt Jockey.png"> Alt Jockey </option>
                <option value="Alt JockeyV2.png"> Alt JockeyV2 </option>
                <option value="Alt Soccer.png"> Alt Soccer </option>
                <option value="Alt SoccerV2.png"> Alt SoccerV2 </option>
                <option value="Artist.png"> Artist </option>
                <option value="ArtistV2.png"> ArtistV2 </option>
                <option value="Astronaut.png"> Astronaut </option>
                <option value="AstronautV2.png"> AstronautV2 </option>
                <option value="Author.png"> Author </option>
                <option value="AuthorV2.png"> AuthorV2 </option>
                <option value="Baseball.png"> Baseball </option>
                <option value="BaseballV2.png"> BaseballV2 </option>
                <option value="Basketball.png"> Basketball </option>
                <option value="BasketballV2.png"> BasketballV2 </option>
                <option value="Body Builder.png"> Body Builder </option>
                <option value="Body BuilderV2.png"> Body BuilderV2 </option>
                <option value="Captain.png"> Captain </option>
                <option value="CaptainV2.png"> CaptainV2 </option>
                <option value="Cowboy.png"> Cowboy </option>
                <option value="CowboyV2.png"> CowboyV2 </option>
                <option value="Dad.png"> Dad </option>
                <option value="DadV2.png"> DadV2 </option>
                <option value="Developer.png"> Developer </option>
                <option value="DeveloperV2.png"> DeveloperV2 </option>
                <option value="Doctor.png"> Doctor </option>
                <option value="DoctorV2.png"> DoctorV2 </option>
                <option value="Firefighter.png"> Firefighter </option>
                <option value="FirefighterV2.png"> FirefighterV2 </option>
                <option value="Football.png"> Football </option>
                <option value="FootballV2.png"> FootballV2 </option>
                <option value="Golfer.png"> Golfer </option>
                <option value="GolferV2.png"> GolferV2 </option>
                <option value="Jockey.png"> Jockey </option>
                <option value="JockeyV2.png"> JockeyV2 </option>
                <option value="Maison Staff.png"> Maison Staff </option>
                <option value="Maison StaffV2.png"> Maison StaffV2 </option>
                <option value="Mom.png"> Mom </option>
                <option value="MomV2.png"> MomV2 </option>
                <option value="Musician.png"> Musician </option>
                <option value="MusicianV2.png"> MusicianV2 </option>
                <option value="NFT Collector.png"> NFT Collector </option>
                <option value="NFT CollectorV2.png"> NFT CollectorV2 </option>
                <option value="Nurse.png"> Nurse </option>
                <option value="NurseV2.png"> NurseV2 </option>
                <option value="Olympian.png"> Olympian </option>
                <option value="OlympianV2.png"> OlympianV2 </option>
                <option value="Photographer.png"> Photographer </option>
                <option value="PhotographerV2.png"> PhotographerV2 </option>
                <option value="Pilot.png"> Pilot </option>
                <option value="PilotV2.png"> PilotV2 </option>
                <option value="Politician.png"> Politician </option>
                <option value="PoliticianV2.png"> PoliticianV2 </option>
                <option value="Power Suit.png"> Power Suit </option>
                <option value="Power SuitV2.png"> Power SuitV2 </option>
                <option value="Racecar Driver.png"> Racecar Driver </option>
                <option value="Racecar DriverV2.png"> Racecar DriverV2 </option>
                <option value="Retired.png"> Retired </option>
                <option value="RetiredV2.png"> RetiredV2 </option>
                <option value="Soccer.png"> Soccer </option>
                <option value="SoccerV2.png"> SoccerV2 </option>
                <option value="Soldier.png"> Soldier </option>
                <option value="SoldierV2.png"> SoldierV2 </option>
                <option value="Spy.png"> Spy </option>
                <option value="SpyV2.png"> SpyV2 </option>
                <option value="Student.png"> Student </option>
                <option value="StudentV2.png"> StudentV2 </option>
                <option value="Tennis.png"> Tennis </option>
                <option value="TennisV2.png"> TennisV2 </option>
                <option value="Trust Fund.png"> Trust Fund </option>
                <option value="Trust FundV2.png"> Trust FundV2 </option>
                <option value="Visionary.png"> Visionary </option>
                <option value="VisionaryV2.png"> VisionaryV2 </option>
                <option value="Wall Street.png"> Wall Street </option>
                <option value="Wall StreetV2.png"> Wall StreetV2 </option>
              </select>
            </div>
          </div>
          <div>
            <label>Fur</label>
            {/* <br /> */}
            <select name="fur" label="Fur" onChange={handleChange}>
              <option value={null} disabled selected>
                Fur
              </option>
              <option value="Black.png"> Black </option>
              <option value="BlackV2.png"> BlackV2 </option>
              <option value="Blue.png"> Blue </option>
              <option value="BlueV2.png"> BlueV2 </option>
              <option value="Ethereal.png"> Ethereal </option>
              <option value="EtherealV2.png"> EtherealV2 </option>
              <option value="Ghost.png"> Ghost </option>
              <option value="GhostV2.png"> GhostV2 </option>
              <option value="Gold.png"> Gold </option>
              <option value="GoldV2.png"> GoldV2 </option>
              <option value="Grey.png"> Grey </option>
              <option value="GreyV2.png"> GreyV2 </option>
              <option value="Mythical.png"> Mythical </option>
              <option value="MythicalV2.png"> MythicalV2 </option>
              <option value="Pink.png"> Pink </option>
              <option value="PinkV2.png"> PinkV2 </option>
              <option value="Purple.png"> Purple </option>
              <option value="PurpleV2.png"> PurpleV2 </option>
              <option value="Red.png"> Red </option>
              <option value="RedV2.png"> RedV2 </option>
              <option value="Silver.png"> Silver </option>
              <option value="SilverV2.png"> SilverV2 </option>
              <option value="Tan.png"> Tan </option>
              <option value="TanV2.png"> TanV2 </option>
              <option value="Tiger.png"> Tiger </option>
              <option value="TigerV2.png"> TigerV2 </option>
              <option value="White.png"> White </option>
              <option value="WhiteV2.png"> WhiteV2 </option>
              <option value="Zebra.png"> Zebra </option>
              <option value="ZebraV2.png"> ZebraV2 </option>
              <option value="Zombie.png"> Zombie </option>
              <option value="ZombieV2.png"> ZombieV2 </option>
            </select>
          </div>

          <div>
            <label>Background</label>

            <select name="background" onChange={handleChange}>
              <option value={null} disabled selected>
                Background
              </option>
              <option value="Blue.png"> Blue </option>
              <option value="BlueV2.png"> BlueV2 </option>
              <option value="Burgandy.png"> Burgandy </option>
              <option value="BurgandyV2.png"> BurgandyV2 </option>
              <option value="Forest.png"> Forest </option>
              <option value="ForestV2.png"> ForestV2 </option>
              <option value="Peach.png"> Peach </option>
              <option value="PeachV2.png"> PeachV2 </option>
              <option value="Purple.png"> Purple </option>
              <option value="PurpleV2.png"> PurpleV2 </option>
              <option value="Rose.png"> Rose </option>
              <option value="RoseV2.png"> RoseV2 </option>
              <option value="Slate.png"> Slate </option>
              <option value="SlateV2.png"> SlateV2 </option>
              <option value="Turquoise.png"> Turquoise </option>
              <option value="TurquoiseV2.png"> TurquoiseV2 </option>
              <option value="Wheat.png"> Wheat </option>
              <option value="WheatV2.png"> WheatV2 </option>
              <option value="Yellow.png"> Yellow </option>
              <option value="YellowV2.png"> YellowV2 </option>

            </select>
          </div>
        </div>
        <div>
          <button onClick={handleSave}>Save</button>
          <p onClick={stateReset}>
            <FontAwesomeIcon className={styles.icon} icon={faTrashAlt} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Compactor;
